'use client'

import { Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { registerGoogleUser } from 'data/api/authentication/requests'
import { getFingerprint } from '@marketplace-web/domain/audit'
import { redirectToPage } from '@marketplace-web/shared/browser'
import { REGISTER_SELECT_TYPE } from 'constants/routes'

import RegisterForm, { FormData } from '../RegisterForm'
import { useRegistrationTracking } from '../../hooks/useRegistrationTracking/useRegistrationTracking'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'

const GoogleRegister = () => {
  const translate = useTranslate()
  const trackRegistrationEvents = useRegistrationTracking()
  const { externalRegisterData } = useAuthenticationContext()

  if (!externalRegisterData) {
    redirectToPage(REGISTER_SELECT_TYPE)

    return null
  }

  const { idToken, realName, email } = externalRegisterData

  async function handleFormSubmit(data: FormData) {
    const fingerprint = await getFingerprint()

    const response = await registerGoogleUser({
      agreeRules: true,
      ...data,
      token: idToken,
      fingerprint,
    })

    await trackRegistrationEvents({
      response,
      authType: 'google',
      email: data.email,
    })

    return response
  }

  return (
    <>
      <div className="u-ui-padding-horizontal-large">
        <Text
          as="h1"
          id="auth_modal_title"
          text={translate('auth.external_register.title')}
          type={Text.Type.Heading}
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
        />
      </div>
      <RegisterForm email={email} realName={realName} onSubmit={handleFormSubmit} />
    </>
  )
}

export default GoogleRegister
